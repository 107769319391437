import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';

import { Content } from '../components/pages/video-series/content';
import { buildPageMetadata } from '../utils/data-builders';

const VideoSeries = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  const { showHeader, showFooter } = acf;
  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Helmet>
        {/* https://wistia.com/support/embed-and-share/channel-embeds */}
        <script
          src="https://fast.wistia.net/assets/external/channel.js?contentOffsetTop=100"
          async
        />
      </Helmet>
      <Header theme="light" withoutActions={showHeader === 'false'} />
      <Content />
      {showFooter !== 'false' && <Footer />}
    </Layout>
  );
};

export default VideoSeries;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata

      acf {
        showHeader
        showFooter
      }
    }
  }
`;
