import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
  min-height: 100vh;
  background-color: rgb(0 0 0);

  .w-hero-background {
    img {
      object-position: top;
      width: 108% !important;
    }
  }

  .w-gallery-view__body {
    padding-bottom: 100px !important;
  }
`;
