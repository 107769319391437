import React from 'react';

import { Wrapper } from './video-series-content.styles';

export const Content = () => (
  <Wrapper>
    {/* Options: https://wistia.com/support/embed-and-share/channel-embeds#channel-embed-options */}
    <div className="wistia_channel wistia_async_yr4a6ql8fj mode=inline contentOffsetTop=100 pageFixedHeaderHeight=100" />
  </Wrapper>
);
